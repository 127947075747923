import React, { createRef } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { Site } from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/Home";
import { datadogRum } from "@datadog/browser-rum";
// import Datadog from "react-datadog";

datadogRum.init({
  applicationId: "2f845b4e-d038-4e6b-93b3-c969c219610a",
  clientToken: "pub4cfef63e92e53ac8aa20007b4df86405",
  site: "datadoghq.com",
  service: "aifa-react-prototype-brains",
  env: "dev",
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  sessionReplaySampleRate: 100,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: "mask-user-input",
});

datadogRum.startSessionReplayRecording();

export const ROUTES = [
  { path: "/", name: "Home", element: <Home />, nodeRef: createRef() },
];
const router = createBrowserRouter([
  {
    path: "/",
    element: <Site />,
    children: ROUTES.map((route) => ({
      index: route.path === "/",
      path: route.path === "/" ? undefined : route.path,
      element: route.element,
    })),
  },
]);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <Datadog
  //   applicationId="a0b1c2d3-e4f5-a6b7-c8d9-e0f1a2b3c4d5"
  //   clientToken="puba0b1c2d3e4f5a6b7c8d9e0f1a2b3c4d5"
  //   service="my-service"
  //   sessionReplayRecording
  // >
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
  // </Datadog>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
